<template>
  <div>
    <h3 v-if="!hideHeader">
      Filing Service &mdash; {{ product.name }}
    </h3>
    <div v-if="ineligibleToOrderProduct(product) && loaded">
      {{ ineligibleToOrderText }}
    </div>
    <div v-else>
      <filing-options
        v-if="loaded"
        submission-label="Add to Cart"
        :loading="loading"
        :product="product"
        :company="company"
        :saved-items="cartItems"
        :disable-domestic-form="isStagelineRoute"
        @submit="addFilingToCart"
        @remove-by-id="removeFromCart"
        @remove-by-jurisdiction="removeCartItemForJurisdiction"
        @remove-by-local-jurisdiction="removeCartItemForJurisdiction"
      />
      <ct-centered-spinner v-if="!loaded" />
    </div>
  </div>
</template>

<script>
  import { dollars, filingMethodTimingNoticeText } from '@/filters'
  import { mapActions, mapGetters } from 'vuex'
  import {
    FILINGS_PACKAGED_WITH_RA_SERVICE,
  } from '@/common/modules/constants'
  import { addRenewalWithFilingProduct, addBoiWithFilingProduct } from '@/components/HireUs/helper'

  export default {
    name: 'FilingCheckout',
    filters: {
      dollars,
      filingMethodTimingNoticeText,
    },
    components: {
      CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
      FilingOptions:     () => import('@/components/HireUs/FilingOptions'),
    },
    props: {
      product: Object,
      company: Object,
      hideHeader: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loaded: false,
        loading: false,
        ineligibleToOrderText: '',
      }
    },
    computed: {
      ...mapGetters('checkout', [
        'findCartItem',
        'cartItems',
        'registeredAgentProductByJurisdiction',
        'formationProductInCart',
        'cartProductsByKind',
        'optionalItemEnabled',
        'findProductBy',
      ]),
      ...mapGetters('companies', [
        'activeRAServices',
        'currentCompany',
      ]),
      // TODO test this
      ...mapGetters('stageline', [
        'isStagelineRoute',
      ]),
      ...mapGetters('orderItems', [
        'eligibleToOrderEin',
        'eligibleToOrderSCorp',
        'requiresFormationFiling',
      ]),
      ...mapGetters('account', [
        'accountType',
      ]),
      ...mapGetters('monitoring', [
        'monitoringServiceActive',
      ]),
      showMonitoring() {
        return this.product.filing_name === 'trademark' && !this.monitoringServiceActive
      },
      existingCartItems() {
        return this.cartItems.filter(i => i.product_id === this.product.id)
      },
    },
    async mounted() {
      await this.loadActiveRAServices({ id: this.company.id })
      await this.setEinEligibilityStatus(this.company.id)
      await this.setSCorpEligibilityStatus(this.company.id)
      this.loaded = true
    },
    methods: {
      ...mapActions('checkout', [
        'addToCart',
        'removeFromCart',
        'resolveOptionalItems',
        'toggleOptionalItem',
      ]),
      ...mapActions('companies', [
        'loadActiveRAServices',
      ]),
      ...mapActions('orderItems', [
        'setEinEligibilityStatus',
        'setSCorpEligibilityStatus',
      ]),
      async addFilingToCart(filingInformation) {
        this.loading = true
        const product = filingInformation.product
        const jurisdiction = filingInformation.jurisdiction
        const filingMethod = filingInformation.filingMethod

        const addedToCart = await this.addToCart({
          ...product,
          filingMethodId: filingMethod.id,
          jurisdiction: jurisdiction,
          localJurisdictionId: filingMethod.local_jurisdiction_id,
        })

        if (addedToCart) {
          this.$emit('product-added', product)

          const filingProduct = this.findCartItem(product.id)
          if (
            !this.hasExistingRAService(jurisdiction) &&
            FILINGS_PACKAGED_WITH_RA_SERVICE.includes(product.filing_name) &&
            this.currentCompany.entity_type !== 'Subsidiary Series LLC'
          ) {
            const raProduct = this.registeredAgentProductByJurisdiction(jurisdiction)
            await this.addToCart({ ...raProduct, kind: 'registered_agent_product', parentItemId: filingProduct.id })


            await addRenewalWithFilingProduct({
              filingProduct: product,
              company: this.currentCompany,
              jurisdiction: jurisdiction,
              productCartItemId: filingProduct.id,
            })
          }
          await addBoiWithFilingProduct(product, filingProduct.id)
        }

        this.loading = false
      },
      hasExistingRAService(jurisdiction) {
        return this.activeRAServices.find(service => service.product.description.includes(jurisdiction))
      },
      removeCartItemForJurisdiction(jurisdiction, filingMethod) {
        if (filingMethod) {
          const item = this.existingCartItems.find(item => item.filing_method_id === filingMethod.id)
          if (item) {
            this.removeFromCart(item)
          }
        }
      },
      ineligibleToOrderProduct(product){
        this.ineligibleToOrderText = `You already have the ${ product.name } product on your account!`
        if (product.filing_name === 'tax id') return !this.eligibleToOrderEin
        if (product.filing_name === 's corp') {
          if (this.requiresFormationFiling) {
            this.ineligibleToOrderText = `You must have a Form a Company order for this company to order ${product.name}!`
            return this.requiresFormationFiling && !this.formationProductInCart
          } return !this.eligibleToOrderSCorp
        }
      },
    },
  }
</script>
